<template>
  <a-modal
    title="日志详情"
    okText="确认"
    :width="900"
    :visible="visible"
    :closable="false"
    :confirmLoading="confirmLoading"
    :cancelButtonProps="{style: {display: 'none'}}"
    @ok="handleCancel">

    <a-spin :spinning="confirmLoading">
      <a-row :gutter="48">
        <a-col :md="12" :sm="24">
          <p>
            <span class="title">
              模块标题：
            </span>
            {{ form.title }}
          </p>
        </a-col>
        <a-col :md="12" :sm="24">
          <p>
            <span class="title">
              请求地址：
            </span>
            {{ form.requestUrl }}
          </p>
        </a-col>
        <a-col :md="12" :sm="24">
          <p>
            <span class="title">
              登录信息：
            </span>
            {{ form.operName?form.operName+"/":"" }}{{ form.operIp }}
          </p>
        </a-col>
        <a-col :md="12" :sm="24">
          <p>
            <span class="title">
              请求方式：
            </span>
            {{ form.requestMethod }}
          </p>
        </a-col>
        <a-col :md="12" :sm="24">
          <p>
            <span class="title">
              操作方法：
            </span>
            {{ form.method }}
          </p>
        </a-col>
        <a-col :md="24" :sm="24">
          <p>
            <span class="title">
              请求参数：
            </span>
            {{ form.requestParam }}
          </p>
        </a-col>
        <a-col :md="24" :sm="24">
          <p>
            <span class="title">
              返回参数：
            </span>
            {{ form.responeResult }}
          </p>
        </a-col>
        <a-col :md="12" :sm="24">
          <p>
            <span class="title">
              操作状态：
            </span>
            <span v-if="form.status===0" >
              正常
            </span>
            <span v-else>
              异常
            </span>
          </p>
        </a-col>
        <a-col :md="12" :sm="24">
          <p>
            <span class="title">
              操作时间：
            </span>
            {{ moment(form.timeStamp).format("YYYY-MM-DD hh:mm:ss") }}
          </p>
        </a-col>
      </a-row>
    </a-spin>
  </a-modal>
</template>

<script>

  export default {
    data () {
      return {
        labelCol: {
          xs: { span: 24 },
          sm: { span: 5 }
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 15 }
        },
        visible: false,
        confirmLoading: false,
        form: {}
      }
    },
    methods: {
      edit (record) {
        this.visible = true
        setTimeout(() => {
          this.form = {
            id: record.id,
            title: record.title,
            businessType: record.businessType,
            method: record.method,
            requestMethod: record.requestMethod,
            operatorType: record.operatorType,
            operId: record.operId,
            operName: record.operName,
            deptId: record.deptId,
            deptName: record.deptName,
            operIp: record.operIp,
            operLocation: record.operLocation,
            requestUrl: record.requestUrl,
            requestParam: record.requestParam,
            responeResult: record.responeResult,
            status: record.status,
            msg: record.msg,
            timeStamp: record.timeStamp
          }
        }, 100)
      },
      handleCancel () {
        this.form = {}
        this.visible = false
      }
    }
  }
</script>
<style lang="less" scoped>

.title{
  font-weight: bold;
}
</style>
